import { ApexOptions } from 'apexcharts'

// any has to be used to satisfy apexcharts
export const EMBEDDED_CHART_TYPES: { [key: string]: any } = {
  hcr_villas_line_eur: 'line',
  hcr_villas_line_percent: 'line',
  hcr_villas_bar_eur: 'bar',
  hcr_villas_bar_percent: 'bar',
}

const hcrVillasLinePercentOptions: ApexOptions = {
  colors: ['#FD0000', '#757576'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    formatter: (v) => `${v}%`,
    offsetY: -5,
    style: {
      colors: ['#000'],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
  },
  xaxis: {
    labels: {
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => `${v}%`,
    },
  },
  stroke: {
    colors: ['#FD0000', '#757576'],
    width: [4, 3],
  },
  chart: {
    background: '#fff',
    toolbar: {
      show: true,
    },
  },
  grid: {
    padding: {
      right: 30,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
  },
}

const hcrVillasLineEurOptions: ApexOptions = {
  colors: ['#FD0000', '#757576'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    formatter: (v) => `${v}€`,
    offsetY: -5,
    style: {
      colors: ['#000'],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
  },
  xaxis: {
    labels: {
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => `${v}€`,
    },
  },
  stroke: {
    colors: ['#FD0000', '#757576'],
    width: [4, 3],
  },
  chart: {
    background: '#fff',
    toolbar: {
      show: true,
    },
  },
  grid: {
    padding: {
      right: 30,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
  },
}

const hcrVillasBarPercentOptions: ApexOptions = {
  colors: ['#FD0000'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    formatter: (v) => `${v}%`,
    offsetY: -5,
    style: {
      colors: ['#000'],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
  },
  xaxis: {
    labels: {
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => `${v}%`,
    },
  },
  chart: {
    background: '#fff',
    toolbar: {
      show: true,
    },
  },
  grid: {
    padding: {
      right: 30,
    },
  },
  legend: {
    show: false,
  },
}

const hcrVillasBarEurOptions: ApexOptions = {
  colors: ['#FD0000'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    formatter: (v) => `${v}€`,
    offsetY: -5,
    style: {
      colors: ['#000'],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
  },
  xaxis: {
    labels: {
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => `${v}€`,
    },
  },
  chart: {
    background: '#fff',
    toolbar: {
      show: true,
    },
  },
  grid: {
    padding: {
      right: 30,
    },
  },
  legend: {
    show: false,
  },
}

export const EMBEDDED_CHART_OPTIONS: { [key: string]: ApexOptions } = {
  hcr_villas_line_percent: hcrVillasLinePercentOptions,
  hcr_villas_bar_percent: hcrVillasBarPercentOptions,
  hcr_villas_line_eur: hcrVillasLineEurOptions,
  hcr_villas_bar_eur: hcrVillasBarEurOptions,
}
